import _behavior from "./sinon/behavior";
import _createSandbox from "./sinon/create-sandbox";
import _extend from "./sinon/util/core/extend";
import _fakeTimers from "./sinon/util/fake-timers";
import _format from "./sinon/util/core/format";
import _nise from "nise";
import _sandbox from "./sinon/sandbox";
import _stub from "./sinon/stub";
import _assert from "./sinon/assert";
import _samsam from "@sinonjs/samsam";
import _restoreObject from "./sinon/restore-object";
import _mockExpectation from "./sinon/mock-expectation";
import _defaultConfig from "./sinon/util/core/default-config";
var exports = {};
var behavior = _behavior;
var createSandbox = _createSandbox;
var extend = _extend;
var fakeTimers = _fakeTimers;
var format = _format;
var nise = _nise;
var Sandbox = _sandbox;
var stub = _stub;
var apiMethods = {
  createSandbox: createSandbox,
  assert: _assert,
  match: _samsam.createMatcher,
  restoreObject: _restoreObject,
  expectation: _mockExpectation,
  defaultConfig: _defaultConfig,
  setFormatter: format.setFormatter,
  // fake timers
  timers: fakeTimers.timers,
  // fake XHR
  xhr: nise.fakeXhr.xhr,
  FakeXMLHttpRequest: nise.fakeXhr.FakeXMLHttpRequest,
  // fake server
  fakeServer: nise.fakeServer,
  fakeServerWithClock: nise.fakeServerWithClock,
  createFakeServer: nise.fakeServer.create.bind(nise.fakeServer),
  createFakeServerWithClock: nise.fakeServerWithClock.create.bind(nise.fakeServerWithClock),
  addBehavior: function (name, fn) {
    behavior.addBehavior(stub, name, fn);
  }
};
var sandbox = new Sandbox();
var api = extend(sandbox, apiMethods);
exports = api;
export default exports;