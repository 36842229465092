import _walkObject from "./util/core/walk-object";
var exports = {};
var walkObject = _walkObject;

function filter(object, property) {
  return object[property].restore && object[property].restore.sinon;
}

function restore(object, property) {
  object[property].restore();
}

function restoreObject(object) {
  return walkObject(restore, object, filter);
}

exports = restoreObject;
export default exports;