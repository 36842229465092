import _commons from "@sinonjs/commons";
import _sandbox from "./sandbox";
var exports = {};
var arrayProto = _commons.prototypes.array;
var Sandbox = _sandbox;
var forEach = arrayProto.forEach;
var push = arrayProto.push;

function prepareSandboxFromConfig(config) {
  var sandbox = new Sandbox();

  if (config.useFakeServer) {
    if (typeof config.useFakeServer === "object") {
      sandbox.serverPrototype = config.useFakeServer;
    }

    sandbox.useFakeServer();
  }

  if (config.useFakeTimers) {
    if (typeof config.useFakeTimers === "object") {
      sandbox.useFakeTimers(config.useFakeTimers);
    } else {
      sandbox.useFakeTimers();
    }
  }

  return sandbox;
}

function exposeValue(sandbox, config, key, value) {
  if (!value) {
    return;
  }

  if (config.injectInto && !(key in config.injectInto)) {
    config.injectInto[key] = value;
    push(sandbox.injectedKeys, key);
  } else {
    push(sandbox.args, value);
  }
}

function createSandbox(config) {
  if (!config) {
    return new Sandbox();
  }

  var configuredSandbox = prepareSandboxFromConfig(config);
  configuredSandbox.args = configuredSandbox.args || [];
  configuredSandbox.injectedKeys = [];
  configuredSandbox.injectInto = config.injectInto;
  var exposed = configuredSandbox.inject({});

  if (config.properties) {
    forEach(config.properties, function (prop) {
      var value = exposed[prop] || prop === "sandbox" && configuredSandbox;
      exposeValue(configuredSandbox, config, prop, value);
    });
  } else {
    exposeValue(configuredSandbox, config, "sandbox");
  }

  return configuredSandbox;
}

exports = createSandbox;
export default exports;